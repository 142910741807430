<template>
<v-container fill-height fluid>
  <v-row class="text-center">
    <v-spacer />
    <v-col cols="12" md="2" v-for="time in times" v-bind:key="time.id">
      <image-text title class="time">
        {{ getText(time) }}
      </image-text>
    </v-col>
    <v-spacer />
  </v-row>
</v-container>
</template>


<script>
import ImageText from "./ImageText.vue";
export default {
  components: {
    ImageText,
  },
  data: () => ({
    endTime: "Feb 22, 2022 10:30:00",
    timeinterval: undefined,
    times: [
      { id: 0, text: "Days", time: 1 },
      { id: 1, text: "Hours", time: 1 },
      { id: 2, text: "Minutes", time: 1 },
      { id: 3, text: "Seconds", time: 1 },
    ],
  }),
  methods: {
    getText: (t) => {
      return t.time + "\n" + t.text;
    },
    updateTimer: function () {
      if (
        this.times[3].time > 0 ||
        this.times[2].time > 0 ||
        this.times[1].time > 0 ||
        this.times[0].time > 0
      ) {
        this.getTimeRemaining();
      } else {
        clearTimeout(this.timeinterval);
      }
    },
    getTimeRemaining: function () {
      let t = Math.abs(Date.parse(new Date(this.endTime)) - Date.parse(new Date()));
      if (t >= 0) {
        this.times[3].time = Math.floor((t / 1000) % 60); //seconds
        this.times[2].time = Math.floor((t / 1000 / 60) % 60); //minutes
        this.times[1].time = Math.floor((t / (1000 * 60 * 60)) % 24); //hours
        this.times[0].time = Math.floor(t / (1000 * 60 * 60 * 24)); //days
      } else {
        this.times[3].time =
          this.times[2].time =
          this.times[1].time =
          this.times[0].time =
            0;

        this.progress = 0;
      }

      this.times[3].text = this.times[3].time === 1 ? "Second" : "Seconds";
      this.times[2].text = this.times[2].time === 1 ? "Minute" : "Minutes";
      this.times[1].text = this.times[1].time === 1 ? "Hour" : "Hours";
      this.times[0].text = this.times[0].time === 1 ? "Day" : "Days";


    },
  },
  created: function () {
    this.updateTimer();
    this.timeinterval = setInterval(this.updateTimer, 1000);
  },
};
</script>

<style scoped>
.time {
  min-width: 180px;
  white-space: pre;
}
</style>
