<template>
  <div class="bgimg bgimg-main">
    <v-img class="image" src="@/assets/background.jpg" ref="top">
      <!-- <v-btn elevation="2" bottom fab @click="scroll" class="downbutton"
        ><v-icon>mdi-chevron-down</v-icon></v-btn
      > -->
      <count-down />
      <bounce-button @click="() => scroll('intro')" />
    </v-img>

    <c-section
      title="Wij zijn getrouwd!"
      color="blue-grey lighten-4"
      ref="intro"
    >
      <p>
        Vier oktober 2014 was een bijzondere dag. Onze eerste ontmoeting! Twaalf
        december 2014 werden we officieel een stel.
      </p>
      <p>
        Drieënhalf jaar later, in april 2018, waren we uitgenodigd om met gezin
        Van der Heijden op vakantie te gaan naar Gambia. Daar hebben we op de
        houtmarkt een fruitschaal meegenomen, gemaakt door Gambianen.
      </p>
      <p>
        Op de vakantie was de verjaardag van schoonvader Nici en hadden we een
        feestje met lokale vrienden. Tijdens het feestje speelde we het
        spelletje “Spin the bottle”, waarbij je een fles moest draaien en de
        persoon die aangewezen werd een vraag moest stellen. Toen Jeffrey de
        fles naar Nici draaide vroeg hij om de hand van Suzanne.
      </p>
      <p>
        Twee jaar later vroeg hij Suzanne zelf pas om haar hand. En nu, nóg een
        jaar later, was het dan eindelijk zover!
      </p>
      <p>
        We hebben een geweldige dag gehad! We hebben enorm genoten met 
        iedereen. Dat belooft veel goeds voor onze toekomst!!
      </p>
    </c-section>

  </div>
</template>

<script>
import cSection from "@/components/Section";
// import cSectionImage from "@/components/SectionImage";
// import cGallery from "@/components/Gallery";
import BounceButton from "@/components/BounceButton";
// import RegisterButton from "@/components/RegisterButton.vue";
import CountDown from "../components/CountDown.vue";
// import ImageText from "../components/ImageText.vue";

export default {
  name: "Index2",

  components: {
    BounceButton,
    cSection,
    // cSectionImage,
    // cGallery,
    // RegisterButton,
    CountDown,
    // ImageText,
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  data: () => ({
    date: "2022-02-22",
    selectedItem: 1,
    events: [
      {
        time: "19:00",
        title: "Feest!",
        description: "DJ",
      },
      {
        time: "17:30",
        title: "Taart aansnijden",
        description: "",
      },
      {
        time: "16:45 - 17:00",
        title: "Ontvangst avondgasten",
        description: "",
      },
      {
        time: "12:00",
        title: "Lunch",
        description: "",
      },
      {
        time: "10:30",
        title: "Ceremonie",
        description: "",
      },
      {
        time: "10:00",
        title: "Ontvangst daggasten",
        description: "",
      },
    ],
  }),
  methods: {
    scroll(target) {
      window.scroll({
        behavior: "smooth",
        left: 0,
        top: this.$refs[target].$el.offsetTop,
      });
    },
  },
};
</script>

<style scoped>
.image {
  /* Fixed position gives nice slide over the picture */
  /* position: fixed; */
  position: relative;
  width: 100%;
  height: 100vh;
  /* height: var(--inner-height); */
  top: 0px;
  left: 0px;

  /* min-height: -webkit-fill-available; */
}

a[href] {
  /* Vuetify blue is too soft to read */
  color: blue;
}
</style>
