<template>
  <v-btn elevation="2" bottom fab @click="$emit('click')" class="downbutton"
    ><v-icon>mdi-chevron-{{ direction }}</v-icon></v-btn
  >
</template>


<script>
export default {
  props: {
    offsetTop: Number,
    up: Boolean,
  },
  computed: {
    direction() {
      return this.up ? "up" : "down";
    }
  }
};
</script>

<style scoped>
.downbutton {
  position: absolute;
  /* bottom: calc(56px + 12px); */
  /* bottom: 24px; */
  bottom: calc(24px + calc(100vh - var(--inner-height)));
  transition: bottom 0.15s ease-in;

  margin: auto;
  width: 56px;
  left: 0;
  right: 0;

  -webkit-animation: bounce 0.6s infinite alternate;
  -moz-animation: bounce 0.6s infinite alternate;
  animation: bounce 0.6s infinite alternate;
}

@-webkit-keyframes bounce {
  to {
    -webkit-transform: scale(1.2);
  }
}
@-moz-keyframes bounce {
  to {
    -moz-transform: scale(1.2);
  }
}
@keyframes bounce {
  to {
    transform: scale(1.2);
  }
}
</style>
