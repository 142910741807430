<template>
  <v-sheet width="100%" :class="'content ' + color">
    <v-container class="pt-4 pb-12 text">
      <v-row class="px-8 py-12 justify-center">
        <v-col cols="12" :md="md" class="text-center" :order="order">
          <h1 class="display-1 font-weight-bold my-3">{{ title }}</h1>
        </v-col>

        <v-col cols="12" :md="md" tag="span">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>


<script>
export default {
  props: {
    title: String,
    color: String,
    last: Boolean,
  },
  computed: {
    md() {
      // return "6";
      return "12";
    },
    order() {

      switch (this.$vuetify.breakpoint.name) {
          case 'xs': return ""
          case 'sm': return ""
          case 'md': return ""

      }

      // return this.last ?  "last" : "";
      return "";
    }
  }
};
</script>

<style scoped>
.content {
  /* top: var(--inner-height); */
  /* top: 100vh; */
  /* min-height: var(--inner-height); */
  min-height: 100vh;
  position: relative;
}

.container {
  max-width: 800px;
}

/* .text {
  max-width: 600px;
} */
</style>
