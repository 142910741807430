<template>
  <span class="text pa-4">
    <h2 v-if="title" class="title-text font-weight-bold">
      <slot/>
    </h2>
    <div v-else>
      <slot/>
    </div>
  </span>
</template>


<script>
export default {
  props: {
    title: Boolean,
  },
};
</script>

<style scoped>

.text {
  color: white;
  background-color: #777;
  display: inline-table;
  opacity: 0.8;
}

.title-text {
  text-align: center;
}

h1 {
  display: inline;
}
</style>
