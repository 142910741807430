import { render, staticRenderFns } from "./Index4.vue?vue&type=template&id=16874c23&scoped=true&"
import script from "./Index4.vue?vue&type=script&lang=js&"
export * from "./Index4.vue?vue&type=script&lang=js&"
import style0 from "./Index4.vue?vue&type=style&index=0&id=16874c23&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16874c23",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
